import React from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { AllRewards, RewardDetails } from './lazy-load';
import RewardContainer from './reward-container';
import MyRewardContainer from './my-reward-container';
import Rewards from './rewards';
const Reward = ({ match }) => {
        return(
            <Switch>
                <Route exact path={`${match.path}`} 
                    render={()=>
                        <Rewards>
                           <RewardContainer />
                        </Rewards>
                    }
                />
                <Route path={`${match.path}/my`} 
                    render={()=>
                        <Rewards>
                            <MyRewardContainer />
                        </Rewards>
                    }
                />
                <Route path={`${match.path}/all`} component={AllRewards}/>
                <Route path={`${match.path}/details/:id`} component={RewardDetails}/>
                <Route render={()=>(<Redirect to="/" />)} />
            </Switch>
        );
};

export default withRouter(Reward);