import { combineReducers } from "redux";
import { quizReducers } from './quizReducer';
import { bannerReducecer } from './bannerReducer';
import { questionReducecer } from './questionReducer';
import { questionPVPReducer } from './questionPVPReducer';
import { answerReducecer } from './answerReducer';
import { faqReducecer } from './faqReducer';
import { privacyPolicyReducecer } from './privacyPolicyReducer';
import { termServiceReducecer } from './termServiceReducer';
import { rewardsReducers } from './redwardsReducer';
import { searchReducers } from './searchReducer';
import { categoryDetailsReducecer } from './categoryDetailsReducer';
import { answerPVPReducecer } from './answerPVPReducer';

export const allReducers = combineReducers({
    quiz: quizReducers,
    search: searchReducers,
    banner: bannerReducecer,
    question: questionReducecer,
    pvpQuestion: questionPVPReducer,
    answer: answerReducecer,
    pvpAnswer: answerPVPReducecer,
    faq: faqReducecer,
    privacyPolicy: privacyPolicyReducecer,
    termServiceReducecer: termServiceReducecer,
    rewards: rewardsReducers,
    categoryDetails: categoryDetailsReducecer
});
