import React from 'react';
import {Switch, Route, Redirect, withRouter} from 'react-router-dom';
import {LeaderBoardDetails, LeaderBoardAll} from './lazy-load';

const LeaderBoard = ({match}) => (
    <Switch>
        <Route path={`${match.path}/all`} component={LeaderBoardAll}/>
        <Route path={`${match.path}/:id`} component={LeaderBoardDetails}/>
        <Route path={`${match.path}/`} render={()=><Redirect to={`${match.url}/all`} />}/>
    </Switch>
);

export default withRouter(LeaderBoard);