import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { MyRewardsComponent } from './lazy-load';
import { isLoggedIn } from '../../helper/authentication';
import './my-reward-container.css';

class MyRewardContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollFixed: false
        }
    }
    componentDidMount() {
        document.getElementById('pageContent').addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        document.getElementById('pageContent').removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = event => {
        if (event.srcElement.scrollTop >= 280)
            this.setState({
                scrollFixed: true
            });
        else
            this.setState({
                scrollFixed: false
            });
            console.log(event.srcElement.scrollTop);
    }
    render() {
        const { scrollFixed } = this.state;
        return(
            <Fragment>
            <div className={classNames("block-title", "display-none", {"scroll-fixed": scrollFixed})}><h3>My Reward</h3></div>
            <div className="pd-10">
                <div className="btn-pref btn-group btn-group-justified btn-group-lg" role="group" aria-label="...">
                    <div className="btn-group" role="group">
                        <Link to='/rewards' className="btn btn-default">Reward</Link>
                    </div>
                    <div className="btn-group" role="group">
                        <Link to='/rewards/my' className="btn btn-default  bg-3">My Reward</Link>
                    </div>
                </div>
                <div className="pd-5" style={{marginTop: '10px'}}>
                    <div className="tab-content">
                        <div className="tab-pane fade in active">
                            {isLoggedIn()? <MyRewardsComponent />: <NotLoggedin />}
                        </div>
                    </div>
                </div>
            </div>
            </Fragment>
        );
    }
}
export default MyRewardContainer;

const NotLoggedin = () => (
    <div style={{
        backgroundColor: 'white',
        padding: '20px',
        textAlign: 'center',
        fontSize: '15px'
    }}>
        <p>Login First! to view Your Rewards.</p>
        <p><Link to={{ pathname: '/login', state: {from: '/rewards/my'}}} className="btn btn-default  bg-3">Login</Link></p>
    </div>
);