import React from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import PrivateRoute from '../components/private-route';
import DisableAuthRoute from '../components/disable-auth-route';
import { Home, Login, Register, FrequentlyAskedQuestions, PrivacyPolicy, TermsOfService, categoryForPVP, searchForPVPPlayer, matchedPVPPlayers, pairDetails, PVPQuestions,PVPResult } from './lazy';
import Reward from './reward';
import Quiz from './quiz';
import Profiles from './profiles';
import Language from './language';
import LeaderBoard from './leaderboard';

import AudioTest from './AudioTest';

import './index.css';

const Routes = ({ location }) => (

  <Switch>
    <Route exact path='/' component={Home} />
    <DisableAuthRoute path='/login' component={Login} />
    <DisableAuthRoute path='/register' component={Register} />
    <PrivateRoute path='/profile' component={Profiles} />
    <Route path='/quiz' component={Quiz} />
    <Route path='/rewards' component={Reward} />
    <Route path='/leaderboard' component={LeaderBoard} />
    <Route exact path='/lang' component={Language} />
    {/*<Route path='/frequently-asked-questions' component={FrequentlyAskedQuestions} />*/}
    <Route path="/privacy-policy" component={PrivacyPolicy} />
    <Route path="/terms-of-service" component={TermsOfService} />
    <Route path="/category-pvp" component={categoryForPVP} />
    <Route path="/serching-pvp-player" component={searchForPVPPlayer} />
    <Route path="/matched-pvp-players" component={matchedPVPPlayers} />
    <Route path="/pair-details" component={pairDetails} />
    <Route path="/pvp-questions" component={PVPQuestions} />
    <Route path="/PVPResult" component={PVPResult} />
    {/* extra route for testing */}
    <Route path="/audio-test" component={AudioTest} />
    <Route render={() => (<Redirect to="/" />)} />
  </Switch>
);


export default withRouter(Routes);