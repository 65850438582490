import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import RedeemYourRewards from '../../components/slider/redeem-your-rewards';
const RewardContainer = ({location}) => (
    <div className="pd-10">
        <div className="btn-pref btn-group btn-group-justified btn-group-lg" role="group" aria-label="...">
            <div className="btn-group" role="group">
			    <Link to='/rewards' className="btn btn-default bg-3">Reward</Link>
            </div>
            <div className="btn-group" role="group">
			    <Link to='/rewards/my' className="btn btn-default">My Reward</Link>
            </div>
        </div>
        <div className="pd-5">
		    <div className="tab-content">
				<div className="tab-pane fade in active">
                    <div className="columns">
                        <div className="column col-12 col-xs-12 pd-0">
                            <div className="columns" style={{margin: '15px 0px'}}>
                                <div className="col-xs-7 pd-0">
                                    <p><strong>Redeem your reward</strong></p>
                                </div>
                                <div className="col-xs-5 pd-0">
                                    <Link to={{ pathname: '/rewards/all', state: { from: location.pathname }}}
                                    className="pull-right text-small">Lebih Lengkap</Link>
                                </div>
                            </div>
                            <RedeemYourRewards />
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default withRouter(RewardContainer);