import React from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import audio from '../../assets/audio.mp3';
import tick from '../../assets/tick.mp3';
import './index.css';
const AudioTest = () => {

    return (
        <React.Fragment>

            <AudioPlayer className="container"
                // autoPlay
                src={tick}
                onPlay={e => console.log("onPlay")}
            // other props here
            />
            <AudioPlayer className="container"
                // autoPlay
                src="https://www.youtube.com/watch?v=C3epz5-obBc"
                onPlay={e => console.log("onPlay")}
            // other props here
            />
        </React.Fragment>

    )
}
export default AudioTest;