import { FETCH_QUIZ_DETAILS } from "../types";

const initialState = {};

export const categoryDetailsReducecer  = (state = initialState, action) => {
    
    switch(action.type){
        case FETCH_QUIZ_DETAILS:
            console.log(action.payload)
            return action.payload;
        default:
            return state;
    }
};