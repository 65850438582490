import React from 'react';
import { withRouter } from 'react-router-dom';
import SlideShow from '../../components/slider/slide-show';
import TemplateWithSlideBar from '../../template/template-with-slide-bar';
import MyCoin from './components/my-coin';

const Rewards = ({children}) => (
	<TemplateWithSlideBar>
		<SlideShow /> 
		<div className="clearfix"></div>
		<div className="block pd-0">
			<MyCoin />
			{children}
		</div>
	</TemplateWithSlideBar>
);

export default withRouter(Rewards);
