import React, { Component } from 'react';
import Header from '../components/header/header';
import "../components/header/sidebar.css";
import SideBar from "../components/header/sidebar";
import Search from './search';

class TemplateWithSlideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openSearchBox: false
        }
    }
    openSearchBox = () => {
        this.setState({openSearchBox: true});
    }
    closeSearchBox = () => {
        this.setState({openSearchBox: false});
    }
    render() {
        const {openSearchBox} = this.state;
        return( 
            <React.Fragment id="outerContainer">
                <Header openSearchBox={this.openSearchBox}/>
                <SideBar pageWrapId={"pageContent"} outerContainerId={"outerContainer"} />
                <Search open={openSearchBox} closeSearchBox={this.closeSearchBox} />
                <div id="pageContent" className="page-content page-content-bg-color" style={{height: '100vh', overflow: 'auto'}}>
                    {this.props.children}
                </div> 
            </React.Fragment> 
        );
    }
};
export default TemplateWithSlideBar;