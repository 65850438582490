import Loadable from 'react-loadable';
import ColorLoader from '../../components/loader/color-loader';
import LandingLoader from '../../loader/landingLoader';

export const Rewards = Loadable({
    loader: () => import( /* webpackChunkName: "rewards" */ './rewards'),
    loading: LandingLoader
});

export const AllRewards = Loadable({
    loader: () => import( /* webpackChunkName: "allRewards" */ './all-rewards'),
    loading: LandingLoader
});

export const RewardDetails = Loadable({
    loader: () => import( /* webpackChunkName: "rewardDetails" */ './reward-details'),
    loading: LandingLoader
});

export const RedeemYourRewards = Loadable({
    loader: () => import( /* webpackChunkName: "redeemYourRewards" */ '../../components/slider/redeem-your-rewards'),
    loading: ColorLoader
});

export const MyRewardsComponent = Loadable({
    loader: () => import( /* webpackChunkName: "myRewardsComponent" */ './components/my-reward-component'),
    loading: ColorLoader
});



export const TopRewards = Loadable({
    loader: () => import( /* webpackChunkName: "topRewards" */ '../../components/slider/top-rewards'),
    loading: ColorLoader
});

