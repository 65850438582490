import React from 'react';
import Swiper from 'react-id-swiper';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import 'swiper/dist/css/swiper.min.css';
import {BASE_URL_IMAGE} from '../../http-api';
const RewardsSlider = (props) => {
    const params = {
        slidesPerView: 3,
        spaceBetween: 10,
    };
    console.log(props);
    const slides = props.rewards.map((reward, key) => (
        <div key={key}>
            <div className="item">
                <Link to={`/rewards/details/${reward.id}`}
                    className="link display-block">
                    <img src={BASE_URL_IMAGE+reward.reward_image} style={{height: '150px', objectFit: 'cover', objectPosition: 'center center'}}/>
                </Link>
                <p className="title" style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%'}}>{reward.title}</p>
                <p className="price">{reward.coin} coins</p>
            </div>
        </div>
    )); 
    
    return(
        <Swiper {...params}>
            {slides}
        </Swiper>
    );
};

export default withRouter(RewardsSlider);