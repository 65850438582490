
import { FETCH_PVP_QUESTION_LIST, SELECTED_PVP_QUESTION, ADD_PVP_PLAYED_QUIZ, RESET_PVP_PLAYED_QUIZ } from "../types";

const initialState = {
    all: [],
    played: [],
    selected: 1
};

export const questionPVPReducer  = (state = initialState, action) => {
    switch(action.type){
        case FETCH_PVP_QUESTION_LIST:
            return {
                ...state,
                all: action.payload
            }
        case SELECTED_PVP_QUESTION:
            
            return {
                ...state,
                selected: action.payload
            }
        case ADD_PVP_PLAYED_QUIZ: 
            return {
                ...state,
                played:[...state.played, action.payload]
            }
        case RESET_PVP_PLAYED_QUIZ:
            return {
                ...state,
                played: initialState.played
            }
        default:
            return state;
    }
};

