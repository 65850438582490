import React from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import PrivateRoute from '../../components/private-route';
import { Profile, Edit } from './lazy-load';
import './profile.css';
const Profiles = ({match}) => (
    <Switch>
        <PrivateRoute exact path={`${match.path}/`} component={Profile}/>
        <PrivateRoute path={`${match.path}/edit`} component={Edit}/>
        <Route render={()=>(<Redirect to="/" />)} />
    </Switch>
);
export default withRouter(Profiles);