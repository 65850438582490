import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { fetchAllRewards } from '../../store/actions';
import RewardsSlider from './rewards-slider';
import { RewardLoader } from '../../content-loader';
import { post } from '../../http-api';
class RedeemYourRewards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }
    componentDidMount() {
        post('getrewardslist')
            .then(res=>{
                if(res.data.success) {
                    const { reward_details } = res.data;
                    this.props.fetchAllRewards(reward_details);
                    this.setState({loading: false});
                }
            })
            .catch(err => {
                console.log(err);
                this.setState({loading: false});
                throw new Error('Could not fetch products. Try again later.');
            });
    }
    render() {
        console.log(this.props.allRewards);
        const { allRewards } = this.props;
        const { loading } = this.state;
        if(loading)
            return(<RewardLoader />);
        return(<Fragment>{allRewards.length?<RewardsSlider rewards={allRewards} />:<RewardLoader />}</Fragment>); 
    }
};

const mapStateToProps = (state) => ({
    allRewards: state.rewards.all,
});

const mapActionsToProps = {
    fetchAllRewards: fetchAllRewards
};

export default connect(mapStateToProps, mapActionsToProps)(RedeemYourRewards);

